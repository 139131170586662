.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(0deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  .pin img {
      transform:  rotate(45deg);
  }
  .overlay3{
    min-width:250px;
    padding: 5px 5px;
    height: auto;
    background-color: rgba(255, 255, 255,1);
    position: absolute;
    border-radius: 5px;
    top:-20px;
    left:0px;
    transform:  rotate(45deg);
    /* display: none; */
    box-shadow:0px 0px 2px 0px #9e8a78;
   
}
.overlay3 h4{
    display: block;
    font-size: 13px !important;
}
  
  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }

  /* a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
  } */
  
  /* .bounce[title]:hover::after {
    content: attr(title);
    position: absolute;
    min-width:100px;
    padding: 10px 20px;
    height: auto;
    background-color: rgba(255, 255, 255,1);
    position: absolute;
    border-radius: 10px;
    left:0;
    transform:  rotate(45deg);
  } */
 
  
  .pulse {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -25px 0 0 -20px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 3px brown;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
  
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }