.tab-div {
    position: relative;
  }
  button:focus{
    outline: none;
  }
  
  .tab-div .active {
    color: #9e8a78; 
    transition: transform 0.3s ease;
    border-bottom:3px solid #9e8a78;

  }
  
 
  
  .tab-div button {
    /* Your button styles */
  }
  
  .tab-div button:not(:last-child) {
    /* Your button margin styles */
  }
  
  .tab-div button:hover {
    /* Define styles for hover effect */
    cursor: pointer;
  }
  
  .tab-div button:active {
    /* Define styles for active (clicked) button */
  }
  
  /* Animation for border highlight */
  .tab-div button:nth-child(1):hover ~ .border-highlight {
    transform: translateX(0%);
  }
  
  .tab-div button:nth-child(2):hover ~ .border-highlight {
    transform: translateX(105%);
  }
  
  .tab-div button:nth-child(3):hover ~ .border-highlight {
    transform: translateX(215%);
  }
  
  .card-category-4 .sp-card-3 {
    width: 550px;
    /* height: 345px; */
    border: 1px solid #efefef;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    margin: 0 10px 10px 0;
}
.card-category-4 .sp-card-3 .card-title {
    color: #525252;
    font-weight: 600;
    font-size: 20px !important;
    padding: 15px 0px !important;
    text-transform: capitalize;
}
.card-category-4 .sp-card-3 ul {
    padding: 0px 10px 10px 10px;
}
.card-category-4 .sp-card-3 table {
    width: 45%;
    float: right;
}
.card-category-4 .sp-card-3 table td {
    padding: 0px !important;
}
.card-category-4 .sp-card-3 .product-details .product-img {
    width: 49%;
    float: left;
    padding-top: 20px;
}
.card-category-4 .sp-card-3 ul li img {
    height: 180px;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.card-category-4 .sp-card-3 .product-details .product-description {
    width: 100%;
    text-align: right;
}
.clear{
    clear: both;
}
.submit{
  background-color: #9e8a78;
  color: #fff;
  font-family: work sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 50px;
  display: inline-block;
  padding: 0 10px;
  margin: auto;
  /* float: left; */
  text-align: center;
  width: 40%;
  cursor: pointer;
  transition: all .5s ease-in-out;
}
.submit:hover{
  color: white;
}

@media screen and (max-width:656px) {
  .card-category-4 .sp-card-3 table {
    width: 100%;
}
}