.custom-modal-overlay {
    position: fixed;
    overflow-y: scroll;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.custom-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 50%;
    top: 140%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
