.automotivejobcard{
   
    margin: 8px 15px;
    width: 90%;
}
.automotivejobcard .border{
  border: 1px solid !important;
}
.automotivejobcard img{
    width: 50px;
}
.automotivejobcard .text-gray{
    color: gray;
}
.automotivejobcard .share_btn{
    width: 17px;
}
.automotivejobcard .card_icon::before{
        content: '';
        width: 20px;
        height: 20px;
        padding: 15px;
        margin: 0 6px;
        /* background: url('../../../public/images/myjobs/card.png') no-repeat; */
        background-size: contain;
        background-position: center;
        
}
.automotivejobcard .time_icon::before{
        content: '';
        width: 20px;
        height: 20px;
        padding: 7px;
        margin: 0 6px 0 0;
        /* background: url('../../../public/images/myjobs/time.png') no-repeat; */
        background-size: contain;
        background-position: center;
        
}
.automotivejobcard .date_icon::before{
        content: '';
        width: 20px;
        height: 20px;
        padding: 7px;
        margin: 0 6px 0 0;
        /* background: url('../../../public/images/myjobs/date.png') no-repeat; */
        background-size: contain;
        background-position: center;
        
}
.automotivejobcard .location_tags{
    display: none !important;
}
.automotivejobcard #location{
    padding: 0px !important;
    margin: 0px !important;
}
.automotivejobcard .map ,#map_size{
    margin-top: 0px !important;
}
.automotivejobcard .map #map_size > div:nth-of-type(1){
    height: 175px !important;
}
.automotivejobcard .icon_spans > .first-child ,.second-child,.third-child,.four-child{
    min-width: 45%;
}
.automotivejobcard .icon_spans span.fs-13 {
    margin: 0px 18px;
}

.automotivejobcard.show-more-visible .show-more {
    animation: slide-down 0.5s ease forwards;
  }
  .automotivejobcard .btn.disabled{
    background-color: gray !important;
  }
  
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translatex(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .show-more {
    display: none;
  }
  
  .show-more-visible .show-more {
    display: block;
  }
  

  .btn.bg_orange:hover{
    background-color: #f46b00;

  }

  @media screen and (min-width:900px) {
    .apply .modal-dialog {
      width:  40% !important;
    }
    
  }
  .apply .modal-title::before{
    content: '';
    /* background: url('../../../public/images/applyform/checklist.png') no-repeat; */
    background-size: contain;
    background-position: center;
    margin-right: 7px;
    width: 30px;
    padding: 10px;
  }
   .person::before{
    content: '';
    /* background: url('../../../public/images/applyform/person.png') no-repeat; */
    background-size: contain;
    background-position: center;
    margin-right: 7px;
    width: 30px;
    padding: 10px;
  }
 
  .apply .apply_input{
  border: 2px solid var(--light-blue--);
  /* border: 2px solid white; */
}
.apply .apply_input label{
  position: relative;
  top: -13px;
  left: 5px;
  background-color: var(--seconday--);
  width: 25%;
  padding-left: 13px;
  height: 13px;
}
.apply .apply_input input{
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  padding: 3px 0 10px 15px;
}
.apply .apply_input input::placeholder{
  color: rgb(156, 154, 154);
}
.apply-form .apply_input:nth-child(1) label{
  width: 100px !important;
}
.apply-form .apply_input:nth-child(2) label{
  width: 115px !important;
}
.apply-form .apply_input:nth-child(3) label{
  width: 75px !important;
}
.apply-form .apply_input:nth-child(4) label{
  width: 55px !important;
}
.apply-form .apply_input:nth-child(5) label{
  width: 140px !important;
}
.apply-form .apply_input:nth-child(6) label{
  width: 130px !important;
}

/* jobs */
.apply-form .job_form .apply_input:nth-child(1) label{
  width: 206px !important;
}
.apply-form .job_form .apply_input:nth-child(2) label{
  width: 96px !important;
}
.apply-form .job_form .apply_input:nth-child(3) label{
  width: 133px !important;
}
.apply-form  .job_form .apply_input:nth-child(4) label{
  width:129px !important;
}
.apply-form .job_form .apply_input:nth-child(5) label{
  width: 175px !important;
}


.apply .bag::before{
  content: '';
  /* background: url('../../../public/images/applyform/bag.png') no-repeat; */
  background-size: contain;
  background-position: center;
  margin-right: 7px;
  width: 30px;
  padding: 12px;
}

.apply input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
  
.automotivejobcard .submit,.custom-modal-overlay .submit {
    background-color: #9e8a78;
    color: #fff;
    font-family: work sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 50px;
    display: inline-block;
    padding: 0 10px;
    margin: auto;
    /* float: left; */
    text-align: center;
    width: 40%;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.tab-div{
  border-bottom: 2px solid;
}