@import 'https://fonts.googleapis.com/css?family=Lato|Prata|Work+Sans:600&amp;display=swap';

* {
    padding: 0;
    margin: 0
}

body {
    background-color: #fff;
    font-family: lato, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #777;
    font-weight: 400;
    letter-spacing: -.2px;
    position: relative;
    overflow-x: hidden
}

h1 {
    font-family: prata, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #fff
}

h2,
h3,
h4,
h5,
h6 {
    font-family: prata, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #282828
}

h1 {
    font-size: 52px;
    line-height: 62px;
    margin: 0 0 10px;
    letter-spacing: -.02em;
    font-weight: 400
}

h2 {
    font-size: 36px;
    color: #303133;
    margin: 0 0 8px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -.04em
}

h3,
h4 {
    margin: 0 0 10px;
    font-weight: 400;
    line-height: 1.4;
    color: #303133;
    letter-spacing: -.01em
}

h3 {
    font-size: 17px
}

h4 {
    font-size: 15px
}

h5,
h6 {
    font-size: 14px;
    margin: 0 0 10px
}

img {
    border: none;
    outline: none
}

ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0
}

p,
li,
a,
span {}

p {
    font-size: 16px;
    margin-bottom: 15px
}

a,
a:hover {
    text-decoration: none
}

a:focus {
    outline: 0;
    text-decoration: none
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #9e8a78
}

::selection {
    background-color: #9e8a78;
    color: #fff
}

-webkit-::selection {
    background-color: #9e8a78;
    color: #fff
}

::-moz-selection {
    background-color: #9e8a78;
    color: #fff
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #888 !important
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #888 !important
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #888 !important
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #888 !important
}

button {
    border: none;
    background: 0 0
}

.padding {
    padding: 80px 0
}

.no-padding {
    padding: 0
}

.padding-10 {
    padding: 10px
}

.padding-15 {
    padding: 15px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mt-20 {
    margin-top: 20px
}

.mt-30 {
    margin-top: 30px
}

.mt-40 {
    margin-top: 40px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.mt-15 {
    margin-left: 15px
}

.fz-28 {
    font-size: 28px
}

.fz-24 {
    font-size: 24px
}

.fz-22 {
    font-size: 22px
}

.fz-20 {
    font-size: 20px
}

.fz-18 {
    font-size: 18px
}

.fz-16 {
    font-size: 16px
}

.display-block {
    display: block
}

.text-black {
    color: #282828
}

.text-white {
    color: #fff
}

.align-left {
    text-align: left
}

.align-right {
    text-align: right
}

.align-center {
    text-align: center
}

.fl-right {
    float: right
}

.fl-left {
    float: left
}

.overlay {
    width: 100%;
    position: relative;
    z-index: 1
}

.overlay:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1
}

.bg-grey {
    background-color: #fcf9f5
}

.bd-bottom {
    border-bottom: 2px solid #eee
}

.bg-dark {
    background-color: #222227
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    height: 100%;
    width: 100%;
    -webkit-transition: all .5s .5s ease;
    -moz-transition: all .5s .5s ease;
    transition: all .5s .2s ease
}

.loader {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
    margin-top: -30px
}

body.loaded #preloader {
    opacity: 0;
    visibility: hidden
}

.btn_group {}

.btn_group a {
    margin: 5px
}

.default_btn {
    font-family: work sans, sans-serif;
    letter-spacing: 0;
    background-color: #9e8a78;
    color: #fff;
    line-height: 45px;
    display: inline-block;
    padding: 0 25px;
    border-radius: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color .4s cubic-bezier(.4, 0, .2, 1);
    -moz-transition: color .4s cubic-bezier(.4, 0, .2, 1);
    -ms-transition: color .4s cubic-bezier(.4, 0, .2, 1);
    -o-transition: color .4s cubic-bezier(.4, 0, .2, 1);
    transition: color .4s cubic-bezier(.4, 0, .2, 1)
}

.default_btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #222227;
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: -webkit-transform .4s cubic-bezier(.4, 0, .2, 1);
    -moz-transition: -moz-transform .4s cubic-bezier(.4, 0, .2, 1);
    -ms-transition: -ms-transform .4s cubic-bezier(.4, 0, .2, 1);
    -o-transition: -o-transform .4s cubic-bezier(.4, 0, .2, 1);
    transition: transform .4s cubic-bezier(.4, 0, .2, 1);
    z-index: -1
}

.default_btn:hover {
    color: #fff
}

.default_btn:hover:before {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1)
}

.default_btn.btn_sm {
    line-height: 40px;
    padding: 0 15px
}

.section_heading {}

.section_heading h3 {
    color: #9e8a78;
    font-weight: 400
}

.section_heading h2 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    letter-spacing: 0
}

.heading-line {
    margin-top: 20px;
    height: 10px;
    background: url(../img/heading-line.png) center center no-repeat;
    position: relative
}

a,
a:hover,
.overlay,
img,
.form-control,
.form-control:hover,
button {
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.header-section {
    background-color: #eee;
    width: 100%;
    height: 80px;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    align-items: center
}

.navbar-fixed-top.header-section {
    background-color: #eee;
    -moz-box-shadow: 0 2px 5px rgba(16, 16, 16, .08);
    -webkit-box-shadow: 0 2px 5px rgba(16, 16, 16, .08);
    box-shadow: 0 2px 5px rgba(16, 16, 16, .08);
    height: 80px;
    border-bottom: 0;
    padding: 0
}

.navbar-fixed-top ul.nav>li>a {
    color: #000;
}

.navbar-fixed-top ul.nav>li>a:hover,
.navbar-fixed-top ul.nav>li>a:focus,
.navbar-fixed-top ul.nav>li.active>a {
    color: #000;
}

.logo-light,
.navbar-fixed-top .logo-light,
.logo-dark,
.navbar-fixed-top .logo-dark,
.navbar-fixed-top.header-section,
.header-section {
    transition: all .3s ease-in-out
}

.navbar {
    border: medium;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    margin: 0;
    position: relative;
    padding: 0;
    justify-content: flex-end;
    
}

.navbar-header {
    padding: 17px 0
}

.navbar-header .brand {
    font-size: 25px;
    color: #fff;
    font-family: poppins, sans-serif;
    letter-spacing: -1px;
    font-weight: 600;
    margin-left: 13px
}

.navbar-header .nav-btn {
    padding: 8px;
    border-radius: 1px
}

.navbar-header .nav-btn .icon-bar {
    background-color: #fff;
    width: 18px
}

.navbar-fixed-top .navbar-header .nav-btn .icon-bar {
    background-color: #777
}

.navbar-header .nav-btn:hover,
.navbar-header .nav-btn:focus {
    opacity: .8
}

#mainmenu li ul {
    background-color: #222227;
    width: 200px;
    height: auto;
    position: absolute;
    left: 0;
    top: 140%;
    visibility: hidden;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    z-index: 999
}

#mainmenu li:hover>ul {
    visibility: visible;
    opacity: 1;
    -ms-filter: "alpha(opacity=100)";
    top: 100%
}

#mainmenu li ul li {
    border-bottom: 1px solid #333;
    display: block;
    position: relative;
    padding: 0
}

#mainmenu li ul li:last-child {
    border: none
}

#mainmenu li ul li a {
    font-family: work sans, sans-serif;
    line-height: 45px;
    display: block;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -.2px;
    color: #ddd;
    text-transform: uppercase;
    -webkit-transition: color .3s ease 0s, padding .3s ease 0s;
    transition: color .3s ease 0s, padding .3s ease 0s
}

#mainmenu li>ul>li:hover a {
    background-color: #9e8a78;
    color: #fff
}

#mainmenu li>ul ul {
    background-color: #fff;
    left: 200px
}

#mainmenu li>ul li:hover>ul li a {
    color: #777
}

#mainmenu li ul li:hover>ul {
    top: 0;
    opacity: 1;
    visibility: visible
}

#mainmenu li>ul li:hover>ul li>a:hover {
    background-color: transparent;
    color: #fff
}

#mainmenu li ul li li a:hover {
    color: #ffca3f !important
}

#navbar {
    z-index: 999;
    padding: 0 15px
}


ul.nav>li {
    position: relative
}

ul.nav>li>a {
    color: #000;
    font-family: work sans, sans-serif;
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 40px;
    z-index: 1
}
.nav-tabs{
    margin-bottom: 30px;
}

ul.nav li.phone {
    font-size: 14px;
    font-family: poppins, sans-serif;
    color: #ddd;
    font-weight: 600;
    line-height: 80px;
    padding-left: 50px
}

ul.nav li.phone i {
    color: #9e8a78;
    margin-right: 5px
}

ul.nav>li>a:hover,
ul.nav>li>a:focus,
ul.nav>li>a.active {
    background: 0 0;
    color: #fff;
    background-color: #9e8a78;
    opacity: 1
}

.menu-btn {
    margin-left: 10px;
    margin-top: 10px
}

.header-btn {
    margin-left: 10px
}

.header-btn .menu-btn {
    background-color: #9e8a78;
    font-family: work sans, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    padding: 0 30px;
    height: 45px;
    line-height: 45px;
    display: block;
    margin: 0
}

.header-btn .menu-btn:hover {
    opacity: .8
}

.menu-wrap {
    position: relative
}

.slicknav_menu {
    background: 0 0;
    padding: 4px 0;
    display: none;
    width: 100%;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 1
}

.slicknav_nav {
    background-color: #fff
}

.slicknav_btn {
    background-color: transparent;
    margin: -51px 0 0
}

.slicknav_nav .slicknav_row:hover,
.slicknav_nav .slicknav_row,
.slicknav_nav a,
.slicknav_nav a:hover {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.slicknav_nav .slicknav_row,
.slicknav_nav a {
    padding: 10px 15px;
    margin: 0;
    color: #777
}

.slicknav_nav a .caret {
    display: none
}

.slicknav_nav ul {
    margin: 0
}

.slicknav_nav ul li a {
    padding-left: 30px;
    font-size: 12px
}

.slicknav_nav .slicknav_row:hover,
.slicknav_nav a:hover {
    background-color: transparent;
    color: #333
}

.main_slide {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    height: 670px
}

.slider_content {}

.slider_content h3 {
    margin-bottom: 20px;
    color: #ddd
}

.slider_content h1 {
    margin-bottom: 20px;
    color: #fff
}

.slider_content p {
    margin-bottom: 20px;
    color: #ddd
}

.main_slider .owl-nav {}

.main_slider .owl-nav div {
    color: #fff;
    width: 60px;
    height: 100px;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    position: absolute;
    left: 0;
    top: calc(50% - 60px);
    opacity: .5;
    transition: all .5s ease-in-out
}

.main_slider .owl-nav div:hover {
    opacity: 1;
    transition: all .5s ease-in-out
}

.main_slider .owl-nav div.owl-next {
    left: auto;
    right: 0
}

.main_slider .owl-dots {
    width: 100%;
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 25px
}

.main_slider .owl-dots div {
    background-color: rgba(255, 255, 255, .4);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;
    transition: all .5s ease-in-out
}

.main_slider .owl-dots div.active {
    background-color: #fff;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    transition: all .5s ease-in-out
}

.hero_section {
    background-image: url(../img/hero-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1
}

.hero_section .video_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: -1;
}
.hero_section .video_bg:after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
}

.hero_section .YTPOverlay {
    background-color: rgba(102, 38, 38, 0.6);
}


.hero_section .hero_content h3 {
    color: #fff;
    margin-bottom: 20px
}

.hero_section .hero_content h1 {
    margin-bottom: 20px
}

.hero_section .hero_content p {
    margin-bottom: 30px;
    color: #fff
}


.about_content h2 {
    font-size: 36px;
    line-height: 46px;
    color: #9e8a78;
    margin-bottom: 30px
}

.about_content p {
    margin-bottom: 30px
}


.about_section .about_content img {
    margin-bottom: 20px
}

.about_section .about_img img {
    width: 60%;
    border-radius: 5px;
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .2)
}

.about_section .about_img_2 {
    margin: -180px 0 0 270px
}

.about_section .about_img_3 {
    margin: -260px 0 0 40px
}

.about_video {
    position: relative
}

.about_video img {
    border-radius: 3px;
    width: 100%
}

.play-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0
}

.play-icon a {
    background-color: #fff;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    color: #9e8a78;
    font-weight: 700;
    display: block;
    transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-animation: btn-anim 1s linear infinite;
    animation: btn-anim 1s linear infinite;
    -webkit-transition: all .5s linear;
    transition: all .5s linear
}

@keyframes btn-anim {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .1), 0 0 0 20px rgba(255, 255, 255, .1), 0 0 0 40px rgba(255, 255, 255, .1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .1), 0 0 0 20px rgba(255, 255, 255, .1), 0 0 0 40px rgba(255, 255, 255, .1)
    }

    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, .1), 0 0 0 40px rgba(255, 255, 255, .1), 0 0 0 60px transparent;
        box-shadow: 0 0 0 20px rgba(255, 255, 255, .1), 0 0 0 40px rgba(255, 255, 255, .1), 0 0 0 60px transparent
    }
}

.why_section {
    position: relative
}

.why_img {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
}

.why_img .img {
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0
}

.why_section {}

.why_contant {
    padding: 100px 0;
    padding-left: 15px
}

why_section ul.list li {
    display: block;
    color: #777;
    font-size: 16px;
    padding-left: 35px;
    position: relative;
    margin-bottom: 20px
}

why_section ul.list li:before {
    background-color: #9e8a78;
    font-family: eleganticons;
    content: "\4e";
    color: #fff;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    font-family: eleganticons;
    content: "\4e";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0
}

.service_section {}

.service_content img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 15px
}

.service_content h3 {
    font-size: 20px
}

.service_content img:hover {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.service_items .col-xs-6 {
    padding: 15px
}

.service_item {
    position: relative;
    overflow: hidden;
    color: #ddd;
    border-radius: 10px
}

.service_item h3 {
    color: #fff
}

.service_item img {
    transition: all .9s ease-in-out;
    width: 100%
}

.service_item:hover img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transition: all 1s ease-in-out
}

.service_item .post_info {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    left: 20px;
    bottom: 20px
}

.service_item .post_info a:hover {
    text-decoration: underline;
    opacity: 1
}

.service_box {
    background-color: #fff;
    padding: 40px 30px;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0 50px 100px 0 rgba(64, 1, 4, .1), 0 -6px 0 0 rgba(248, 99, 107, .004);
    box-shadow: 0 50px 100px 0 rgba(64, 1, 4, .1), 0 -6px 0 0 rgba(248, 99, 107, .004)
}

.service_box i {
    font-size: 50px;
    margin-bottom: 20px;
    color: #9e8a78;
    display: block
}

.service_box h3 {
    font-size: 22px
}

.book_section {
    background-color: #222227;
    position: relative
}

.book_bg {
    background-image: url(../img/book-bg.jpg);
    background-repeat: no-repeat;
    background-position: center left;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0
}

.map_pattern {
    background-image: url(../img/satellite-map.png);
    background-size: contain;
    background-position: right center;
    opacity: .05;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0
}


.appointment_form .book_content h2 {
    color: #fff
}

.appointment_form .book_content p {
    color: #999
}

.appointment_form .book_content {
    margin-bottom: 30px
}

.appointment_form {
    padding: 40px
}

.appointment_form .form-group {
    margin-bottom: 0
}

.appointment_form .form-control {
    background-color: transparent;
    height: 55px;
    border: 3px solid rgba(255, 255, 255, .3);
    line-height: 52px;
    color: #fff;
    border-radius: 0
}

.appointment_form .form-control:hover {
    border: 3px solid rgba(255, 255, 255, .3)
}

.appointment_form .form-control:focus {
    border: 3px solid rgba(255, 255, 255, .5);
    box-shadow: none
}

select.form-control:not([size]):not([multiple]) {
    height: 55px
}

.nice-select.form-control {
    width: 100%;
    line-height: 52px;
    padding: 0 15px
}

.nice-select.form-control span {
    color: #999;
    font-size: 15px
}

.nice-select .list {
    background-color: #222227;
    border-radius: 0;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: #9e8a78
}

.nice-select .option.selected {
    display: none;
}

.nice-select .option {
    border-bottom: 1px solid #444;
    line-height: 45px;
}

select option {
    line-height: 45px !important;
    background-color: #9e8a78;
    color:#fff;
    padding: 10px 20px !important;
}
select option:hover{
    background-color: #9e8a78 !important;
}  

.appointment_form .default_btn {
    margin-top: 15px
}

.appointment_form .default_btn:hover {
    cursor: pointer
}

.appointment_form .default_btn:focus {
    border: none;
    outline: none
}

#msg-status {
    display: none
}

#msg-status.alert-danger,
#msg-status.alert-success {
    display: block;
    margin: 20px 0 0
}

.gallery_section {}

.gallery_section .portfolio_filter {
    display: block;
    text-align: center;
    overflow: hidden
}

.gallery_section .portfolio_filter li {
    background-color: #eee;
    color: #111;
    border: 1px solid #ddd;
    display: inline-block;
    margin-left: -4px;
    padding: 15px 25px;
    transition: all .5s ease-in-out;
    cursor: pointer
}

.gallery_section .portfolio_filter li.active {
    background-color: #00c4ff;
    color: #fff;
    transition: all .5s ease-in-out
}


.gallery_section .portfolio_items li {
    padding: 1px
}

.gallery_section .portfolio_items .portfolio_item {
    position: relative;
    overflow: hidden;
    margin: 0
}

.gallery_section .portfolio_items .portfolio_item img {
    width: 100%
}

.gallery_section .portfolio_items .portfolio_item .overlay {
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0
}

.portfolio_items .portfolio_item:hover {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.portfolio_items .portfolio_item .overlay a {
    width: 100%;
    height: 100%;
    display: table;
    color: #fff
}

.portfolio_items .portfolio_item .overlay h3 {
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    transform: translateY(-10px);
    transition: all .3s ease-in-out
}

.portfolio_items .portfolio_item .overlay span {
    display: block;
    letter-spacing: 1px;
    transform: translateY(10px);
    transition: all .3s ease-in-out
}

.portfolio_items .portfolio_item:hover .overlay span,
.portfolio_items .portfolio_item:hover .overlay h3 {
    transform: translateY(0px);
    animation-delay: .2s;
    transition: all .6s ease-in-out
}

.gallery_section .gallery_filter {
    display: block;
    text-align: center;
    overflow: hidden
}

.gallery_section .gallery_filter li {
    font-family: work sans, sans-serif;
    color: #555;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    margin: 0 10px;
    transition: all .5s ease-in-out;
    cursor: pointer
}

.gallery_section .gallery_filter li.active {
    color: #9e8a78;
    transition: all .5s ease-in-out
}

.team_section .team_members .col-xs-6 {
    padding: 15px
}

.team_section .team_members .team_member {
    position: relative;
    overflow: hidden
}

.team_section .team_members .team_member img {
    width: 100%
}

.team_section .team_members .team_member .overlay {
    background-color: rgba(158, 138, 120, .8);
    display: block;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    position: absolute;
    left: 0;
    bottom: -100px;
    width: 100%
}

.team_section .team_members .team_member:hover .overlay {
    bottom: 0
}

.team_section .team_members .team_member:hover img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.team_section .team_members .team_member .overlay h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 0
}

.team_section .team_members .team_member .overlay p {
    margin: 0 0 5px;
    font-family: work sans, sans-serif;
    /* text-transform: uppercase; */
    font-size: 12px;
    font-weight: 600
}

.testimonial_section {
    background-image: url(../img/testimonial.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0 0;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 200px 0
}

.testimonial_section i {
    border: 2px solid #fff;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 15px
}

.testimonial_items {}

.testimonial_item {
    color: #fff;
    text-align: center
}

.testimonial_item h4 {
    font-size: 14px;
    margin: 0 0 25px
}

.testimonial_item p {
    font-family: prata, sans-serif;
    font-size: 24px;
    line-height: 36px
}

.testimonial_items .owl-dots {
    display: block;
    text-align: center
}

.testimonial_items .owl-dots .owl-dot {
    background-color: #ddd;
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin: 0 3px;
    -webkit-transform: scale(.6);
    transform: scale(.6);
    transition: all .3s ease-in-out
}

.testimonial_items .owl-dots .owl-dot.active {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .3s ease-in-out
}

.pricing_section .price_wrap {}

.pricing_items .col-md-4 {
    padding: 15px
}

.pricing_section .price_wrap h3 {
    background-color: #9e8a78;
    display: inline-block;
    padding: 10px 15px;
    color: #fff;
    margin: 0 0 20px
}

.pricing_section .price_wrap .price_list {}

.pricing_section .price_wrap .price_list li {
    margin-bottom: 40px;
    position: relative
}

.pricing_section .price_wrap .price_list li h4 {
    position: relative;
    color: #333;
    font-size: 18px;
    letter-spacing: .5px;
    margin: 0 0 6px;
    overflow: hidden;
    width: 90%
}

.pricing_section .price_wrap .price_list li h4:after {
    color: #bbb;
    content: "--------------------""--------------------""--------------------""--------------------";
    font-weight: 400;
    margin-left: 10px;
    position: absolute;
    top: -2px;
    white-space: nowrap;
    width: 0
}

.pricing_section .price_wrap .price_list li span.price {
    font-family: prata, sans-serif;
    font-size: 24px;
    color: #9e8a78;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: 0
}

.pricing_section .price_wrap .price_list li p {
    margin: 0
}

.pricing_section .menu_middle_content {}

.pricing_section .menu_middle_content img {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 15px
}

.cta_section {
    background-image: url(../img/cta.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0 0;
    background-size: cover;
    padding: 200px 0;
    width: 100%
}

.cta_section .cta_content h2 {
    color: #fff;
    font-size: 42px;
    line-height: 56px;
    margin-bottom: 15px
}

.cta_section .cta_content p {
    color: #ddd
}

.pro_cta_content {}

.pro_cta_content h2 {}

.pro_cta_content p {
    margin-bottom: 30px
}

.content_section {}

.content_section .col-lg-4 {
    padding: 0
}

.content_wrap {
    position: relative
}

.content_wrap:before {
    content: '';
    border: 2px solid rgba(255, 255, 255, .3);
    position: absolute;
    right: 30px;
    left: 30px;
    top: 30px;
    bottom: 30px
}

.content_wrap img {
    width: 100%
}

.content_info {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 80px
}

.content_inner {}

.content_inner h2 {
    font-size: 42px;
    color: #fff
}

.content_inner h3 {
    color: #ddd;
    margin-bottom: 20px
}

.content_inner a {
    background-color: #9e8a78;
    padding: 0 30px;
    border-radius: 0;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    font-family: work sans, sans-serif;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase
}

.content_inner a:hover {
    opacity: .8
}

.not_found_section {
    padding: 120px 0
}

.blog-section {}

.blog-wrap {}

.blog-classic .col-lg-12:not(:last-of-type) {
    margin-bottom: 30px
}

.blog-grid {
    margin-top: -15px
}

.blog-item {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1)
}

.blog-item .blog-thumb {
    position: relative
}

.blog-item .blog-thumb .category {
    background-color: #9e8a78;
    padding: 5px 15px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: work sans, sans-serif;
    position: absolute;
    width: auto;
    height: auto;
    left: 0;
    bottom: 0
}

.blog-item .blog-thumb .category a {
    color: #fff
}

.blog-item .blog-thumb img {
    width: 100%;
    margin: 0
}

.blog-item .blog-thumb img:hover {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.blog-content {
    background-color: #fff;
    padding: 30px
}

.blog-content h3 {
    line-height: 24px
}

.blog-content h3 a {
    color: #303133;
    font-size: 18px;
    line-height: 28px
}

.blog-content h3 a:hover {
    color: #9e8a78
}

.blog-content .read-more {
    font-family: work sans, sans-serif;
    color: #303133;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-left: 40px
}

.blog-content .read-more:hover {
    color: #9e8a78
}

.blog-content .read-more:before {
    background-color: #9e8a78;
    position: absolute;
    content: "";
    width: 30px;
    height: 4px;
    left: 0;
    top: calc(50% - 2px)
}

.pagination-wrap {}

.pagination-wrap li {
    display: inline-block;
    margin: 0 5px
}

.pagination-wrap.text-left li {
    margin: 0 10px 0 0
}

.pagination-wrap li a {
    border: 1px solid #e5e5e5;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    color: #303133;
    font-weight: 600;
    border-radius: 2px
}

.pagination-wrap li a:hover {
    background-color: #9e8a78;
    color: #fff;
    opacity: 1;
    text-decoration: none
}

.pagination-wrap li a.active {
    background-color: #9e8a78;
    border: 1px solid #9e8a78;
    color: #fff
}

.sidebar-wrap {
    padding-left: 40px
}

.sidebar-wrap .widget-content {
    margin-bottom: 40px
}

.sidebar-wrap .widget-content h4 {
    font-size: 20px;
    position: relative;
    margin-bottom: 30px
}

.sidebar-wrap .widget-content h4:before {
    background-color: #9e8a78;
    position: absolute;
    content: "";
    width: 20%;
    height: 3px;
    left: 0;
    bottom: -10px
}

.sidebar-wrap .widget-content .widget-links li a {
    color: #303133
}

.sidebar-wrap .widget-content .widget-links li a:hover {
    text-decoration: underline
}

.search-form {
    position: relative;
    margin-left: -2px
}

.search-form .form-control {
    background-color: #fcf9f5;
    box-shadow: none;
    width: 100%;
    display: block;
    border: 1px solid #e5e5e5;
    color: #303133;
    height: auto;
    padding: 15px 20px;
    border-radius: 2px;
    padding-right: 60px
}

.search-form .search-btn {
    background-color: transparent;
    font-size: 15px;
    color: #303133;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 10px 0;
    opacity: .6
}

.search-form .search-btn:focus,
.search-form .search-btn:hover {
    opacity: 1;
    cursor: pointer
}

.search-form input::-webkit-input-placeholder {
    color: #6a8695 !important
}

.search-form input:-moz-placeholder {
    color: #6a8695 !important
}

.search-form input::-moz-placeholder {
    color: #6a8695 !important
}

.search-form input:-ms-input-placeholder {
    color: #6a8695 !important
}

.thumb-post {}

.thumb-post li {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: top;
    margin-bottom: 20px
}

.thumb-post li:last-child {
    margin-bottom: 0
}

.thumb-post li img {
    width: 100px;
    display: block
}

.thumb-post li a {
    font-family: prata, sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #303133;
    padding-left: 15px;
    letter-spacing: -.5px
}

.thumb-post li a:hover {
    color: #9e8a78;
    text-decoration: underline
}

.widget-content .tags {}

.widget-content .tags li {
    display: inline-block
}

.widget-content .tags li a {
    display: inline-block;
    background-color: #9e8a78;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    margin: 3px
}

.widget-content .tags li a:hover {
    background-color: #303133;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1)
}

.blog-thumb {}

.blog-thumb img {
    width: 100%;
    margin-bottom: 20px
}

.blog-single-content {}

.blog-single-content h2 a {
    font-size: 28px;
    color: #303133
}

.blog-single-content h2 a:hover {
    color: #9e8a78
}

.single-post-meta {
    margin-bottom: 20px
}

.single-post-meta li {
    display: inline-block;
    margin-right: 20px
}

.single-post-meta li i {
    font-size: 12px;
    color: #9e8a78;
    margin-right: 5px
}

.single-post-meta li a {
    display: inline-block;
    font-family: work sans, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #303133
}

blockquote {
    background-color: #fcf9f5;
    padding: 40px;
    border-left: 4px solid #9e8a78;
    margin: 30px 0;
    position: relative;
    z-index: 1
}

blockquote p {
    font-family: prata, sans-serif;
    font-size: 22px;
    line-height: 32px;
    color: #303133;
    font-size: 20px
}

blockquote span {
    display: block;
    margin-top: 20px;
    color: #303133
}

.post-tags {
    margin-top: 30px
}

.post-tags li {
    display: inline-block
}

.post-tags li a {
    display: inline-block;
    background-color: #9e8a78;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    margin: 3px
}

.post-tags li a:hover {
    background-color: #303133;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1)
}

.author-box {
    display: flex;
    align-items: center;
    padding: 30px;
    margin-top: 30px;
    border-left: 4px solid #9e8a78
}

.author-box img {
    border-radius: 50%
}

.author-info {
    padding-left: 30px
}

.author-info h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 5px
}

.author-box .social-icon {}

.author-box .social-icon li {
    display: inline-block;
    margin-right: 8px
}

.author-box .social-icon li a {
    font-size: 14px;
    color: #303133
}

.author-box .social-icon li a:hover {
    color: #9e8a78
}

.post-navigation {
    border: 1px solid #e5e5e5;
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0
}

.post-navigation .col {
    padding: 20px
}

.post-navigation .col:not(:last-of-type) {
    border-right: 1px solid rgba(17, 17, 17, .04)
}

.post-navigation .col a {
    color: #303133;
    font-size: 12px;
    text-transform: uppercase;
    font-family: work sans, sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center
}

.post-navigation .col.next-post a {
    justify-content: flex-end
}

.post-navigation .col i {
    display: inline-block;
    font-size: 14px
}

.post-navigation .ti-arrow-left {
    margin-right: 10px
}

.post-navigation .ti-arrow-right {
    margin-left: 10px
}

.post-navigation .col a:hover {
    color: #9e8a78
}

.post-navigation .col.prev-post .fa {
    margin-right: 10px
}

.post-navigation .col.next-post .fa {
    margin-left: 10px
}

.blog-single-wrap .comments-area {
    margin-top: 40px
}

.blog-single-wrap .comments-area .comments {
    border-bottom: 0
}

.blog-single-wrap .comments-area li>div {
    border-bottom: 1px solid rgba(17, 17, 17, .05);
    padding: 35px
}

.blog-single-wrap .comments-area ol {
    list-style-type: none;
    padding-left: 0
}

.blog-single-wrap .comments-area ol ul {
    padding-left: 30px;
    list-style-type: none;
    margin: 0
}

.blog-single-wrap .comments-area ol>li:last-child div {
    border-bottom: 0
}

.blog-single-wrap .comments-area .comments-title {
    font-size: 22px;
    font-weight: 600
}

.blog-single-wrap .comments-area li>div {
    position: relative
}

.blog-single-wrap .comments-area .comment-thumb {
    position: absolute;
    left: 35px
}

.blog-single-wrap .comments-area .comment-thumb .comment-img {
    width: 80px;
    height: 80px;
    border-radius: 50%
}

.blog-single-wrap .comments-area .comment-thumb .comment-img img {
    border-radius: 50%
}

.blog-single-wrap .comments-area .comment-main-area {
    padding-left: 120px
}

.blog-single-wrap .comments-area .comment-main-area p {
    margin-bottom: 20px
}

.blog-single-wrap .comments-area .comments-meta h4 {
    font-family: prata, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -1px
}

.blog-single-wrap .comments-area .comments-meta h4 span {
    font-family: work sans, sans-serif;
    font-weight: 600;
    text-transform: none;
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px
}

.blog-single-wrap .comments-area .comment-reply-link {
    font-family: work sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #9e8a78;
    display: inline-block;
    text-transform: uppercase;
    padding-left: 35px;
    position: relative
}

.blog-single-wrap .comments-area .comment-reply-link:before {
    background-color: #9e8a78;
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    left: 0;
    top: calc(50% - 1px)
}

.blog-single-wrap .comments-area .comment-reply-link:hover {
    text-decoration: underline
}

.blog-single-wrap .comment-respond {}

.blog-single-wrap .comment-respond .comment-reply-title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 20px
}

.blog-single-wrap .comment-respond form input,
.blog-single-wrap .comment-respond form textarea {
    background-color: #fcf9f5;
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 50px;
    padding: 6px 15px;
    margin-bottom: 15px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: all .3s
}

.blog-single-wrap .comment-respond form input:focus,
.blog-single-wrap .comment-respond form textarea:focus {
    border-color: #9e8a78
}

.blog-single-wrap .comment-respond form textarea {
    height: 200px;
    padding: 15px
}

.blog-single-wrap .comment-respond .form-inputs {
    overflow: hidden
}

.blog-single-wrap .comment-respond .form-inputs>input:nth-child(2) {
    width: 49%;
    float: left
}

.blog-single-wrap .comment-respond .form-inputs>input:nth-child(3) {
    width: 49%;
    float: right
}

.blog-single-wrap .comment-respond .form-submit input {
    font-family: work sans, sans-serif;
    max-width: 180px;
    background-color: #9e8a78;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 0;
    text-transform: uppercase
}

.blog-single-wrap .comment-respond .form-submit input:hover {
    opacity: .9
}

.sponsor_section {
    padding: 60px 0
}


.sponsor_section .sponsor_item img {
    width: 100%
}

.sponsor_section .sponsor_items li:hover img {
    -webkit-transform: scale(.9);
    transform: scale(.9)
}

.widget_section {
    background-color: #222227;
    padding: 20px 0;
}

.widget_social {
    display: block;
    text-align: left
}

.widget_social li {
    display: inline-block
}

.widget_social li a {
    color: #777;
    font-size: 12px;
    margin-right: 20px
}

.widget_social li a:hover {
    color: #9e8a78
}

.opening_time {}

.opening_time li {
    color: #999;
    line-height: 35px
}

.footer_widget {
    color: #fff
}

.footer_widget h3 {
    color: #fff
}

.footer_widget p {
    color: #999
}

.subscribe_form {
    display: block;
    text-align: center;
    padding: 5px 0
}

.subscribe_form p {
    color: #999
}

.subscribe_form .form_input {
    display: block;
    background-color: rgba(255, 255, 255, .4);
    color: #fff;
    border: none;
    font-size: 14px;
    line-height: 50px;
    padding: 0 10px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    transition: all .5s ease-in-out
}

.subscribe_form .form_input:focus {
    box-shadow: none;
    outline: none
}

.subscribe_form .submit {
    background-color: #9e8a78;
    color: #fff;
    font-family: work sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 50px;
    display: inline-block;
    padding: 0 10px;
    float: left;
    width: 100%;
    cursor: pointer;
    transition: all .5s ease-in-out
}

.subscribe_form .submit:hover {
    background-color: #897666;
    transition: all .5s ease-in-out
}

#subscribe-result {
    display: none;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #deaa6e
}

#subscribe-result.subs-result {
    display: block
}

.subscription-error {
    color: #b60000
}

.subscription-success {
    color: #333
}

.subscribe_form input::-webkit-input-placeholder {
    color: #ddd !important
}

.subscribe_form input:-moz-placeholder {
    color: #ddd !important
}

.subscribe_form input::-moz-placeholder {
    color: #ddd !important
}

.subscribe_form input:-ms-input-placeholder {
    color: #ddd !important
}

.footer_section {
    display: block;
    background-color: #222227;
    border-top: 1px solid #333;
    padding: 30px 0
}

.footer_section .copyright {
    font-size: 14px;
    color: #ddd
}

.footer_social {
    display: block;
    text-align: right
}

.footer_social li {
    display: inline-block
}

.footer_social li a {
    font-family: work sans, sans-serif;
    color: #ddd;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 20px
}

.footer_social li a:hover {
    color: #9e8a78
}

.page_header {
    background-image: url(../img/page-header.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    height: 400px;
    position: relative;
    z-index: 1
}


.page_header .section_heading h2 {
    color: #fff
}

.page_content_section {
    border-bottom: 2px solid #eee
}

.page_content_wrap {
    padding: 35px 0
}

.breadcrumb {
    background-color: transparent;
    position: absolute;
    right: 0;
    top: calc(50% - 5px)
}

.breadcrumb li {}

.breadcrumb li a {
    color: #ddd
}

.breadcrumb li a:hover {
    color: #fff
}

.contact-section {
    position: relative;
    z-index: 1
}

.contact-info h3 {
    line-height: 28px;
    margin-bottom: 20px
}

.contact-info h4 {
    font-size: 15px;
    line-height: 28px
}

.contact-info span {
    margin-right: 5px
}

.contact-form {
    background-color: #fff;
    padding: 50px 40px;
    -webkit-box-shadow: 0 50px 100px 0 rgba(64, 1, 4, .1), 0 -6px 0 0 rgba(248, 99, 107, .004);
    box-shadow: 0 50px 100px 0 rgba(64, 1, 4, .1), 0 -6px 0 0 rgba(248, 99, 107, .004);
    border-radius: 3px
}

.form-control {
    background-color: #fff;
    border-radius: 0;
    padding: 15px 10px;
    box-shadow: none;
    border: 2px solid #eee
}

.form-control:focus {
    border-color: #9e8a78;
    box-shadow: none;
    outline: none
}

.form-group .default-btn {
    box-shadow: none
}

#form-messages {
    display: none
}

#form-messages.alert-danger,
#form-messages.alert-success {
    display: block
}

#google-map {
    width: 100%;
    height: 400px
}

#scroll-to-top {
    background-color: #9e8a78;
    display: none;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 14px;
    line-height: 35px;
    color: #fff;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999
}

#scroll-to-top:hover {
    background-color: #897666;
    color: #fff
}


/* Customer CSS */
.service_section .row .service_box{
    transition:0.5s;
    mix-blend-mode: multiply;
}
.service_section .row:hover .col-lg-3 .service_box{
    transform:rotateY(-30deg);
    transform:scale(0.9);

}
.service_section .row .col-lg-3:hover .service_box{
    transform:rotateY(0deg);
    transform: scale(1.05);
    box-shadow:inset 0px 0px 5px #eee;
}
.service_section .row .col-lg-3 .service_box:hover ~ .service_box{
    transform:rotateY(30deg);
}



/* cUSTOMER css */


.team_section .team_members .col-xs-6 {
    padding: 15px
}

.team_section .team_members .team_member {
    position: relative;
    overflow: hidden
}

.team_section .team_members .team_member img {
    width: 100%
}

.team_section .team_members .team_member .overlay2 {
    background-color: rgba(158, 138, 120, .8);
    /* background-color: rgba(255, 255, 255, .8); */
    display: block;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    position: absolute;
    left: 0;
    bottom: -100%;
    width: 100%;
    height: 100%;
    transition:0.5s;
}

.team_section .team_members .team_member:hover .overlay2 {
    bottom: 0;
}
.team_section .team_members .team_member:hover .overlay2 .table td, .table th {
    padding: .55rem;
    vertical-align: top;
    border:0px;
}
.team_section .team_members .team_member .overlay h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 0;

}

.team_section .team_members .team_member .overlay2 p {
    margin: 0 0 5px;
    font-family: work sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color:#fff;
}

.table td, .table th {
    padding: .55rem;
    vertical-align: top;
    border:0px;
    text-align:center;
}


.makeappointment{
    background-color: #9e8a78;
    color:#fff;
    border-radius:0px;
}
.makeappointment:hover{
    color:#fff;
}
input[type="date"]{
    padding:12px 10px;
}

input[type="time"]{
    padding:12px 10px;
}


/* User Dashboard CSS */
.nav-pills {
    display: block;
}
.nav-pills li {                   
    margin-bottom:5px;
    border: 1px solid #eee;
}
.nav-pills li a{
    line-height:30px !important;
    padding:10px 20px !important;
    width: 100%;
    color:#222227 !important;
}
.fadeInRight h4{
    border:1px solid #9e8a78;
    padding: 15px;
}
.fadeInRight h4 span{
    margin-left:10px;
    font-weight: bold;
}
th,td{
    font-size:12px;
    padding: 10px;
    line-height: 20px;
}
.pricingul li{
    margin-left:10px;
    font-weight:bold;
}   







/* CATRT DESIGN */

.card {
    width:100%;
    border: none;
    border-radius: 10px;
    /* background-color: #f2f5f8; */
    /* box-shadow:inset 0px 0px 3px 0px #000; */
}
.card span small{
    line-height:10px !important;
}

.stats {
    background: #f2f5f8 !important;
    color: #000 !important
}

.articles {
    font-size: 10px;
    color: #a1aab9
}

.number1 {
    font-weight: 500
}

.followers {
    font-size: 10px;
    color: #a1aab9
}

.number2 {
    font-weight: 500
}

.rating {
    font-size: 10px;
    color: #a1aab9
}

.number3 {
    font-weight: 500
}


/* Revoew */
.center .stars{
    width:80%;
    margin:20px auto;
    height: 80px;
    /* text-align: center; */
    padding-right:30px;
}
.stars input{
    display: none;
}
.stars label{
    float: right;
    font-size: 40px;
    color: lightgrey;
    margin: 0 5px;  
    text-shadow: 1px 1px #bbb;
}
.stars label:before{
    content: '★';
}
.stars input:checked ~ label{
    color: gold;
/* text-shadow: 1px 1px #c60; */
}
.stars:not(:checked) > label:hover,
.stars:not(:checked) > label:hover ~ label{
    color: #3c2a19;
}
.stars input:checked > label:hover,
.stars input:checked > label:hover ~ label{
    color: #3c2a19;
    text-shadow: 1px 1px #3c2a19;
}
.team_section .team_members .team_member {
    border-radius: 50%;
    height: 260px;
    width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nice option:disabled {
    /* color: #aaa; Change color for disabled options */
    background-color: #d03d3d; /* Change background color for disabled options */
  }

  /* card */
  #card{
    border-radius: 10px;
    /* height: 342px; */
    width: 300px;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #9e8a78;
    /* /* justify-content: center; */
  }
  
  #card img{
    height: 138px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    position: relative;
  }
  
  #card h1{
    font-size: 22px;
    /* margin-top: 8px; */
    margin: 0px !important;
    line-height: 36px;
  }
  
  #card p{
    font-size: 14px;
    /* margin-bottom: 20px; */
    margin: 0px !important;
  }
  
  
  
  #book{
    background-color: #9e8a78;
    color: white;
    width: 96%;
    font-weight: 700;
    font-size: 15px;
    padding: 8px 12px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
  
  #card i{
    color: red;
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -180%) scale(0);
    opacity: 0.8;
    transition: all ease 0.4s;
  }
  @media only screen and (max-width: 992px){
      .header-section .logo img {
          display: block;
          margin-left: -100px;
        }
        #mainmenu .nav{
            position: absolute;
            top: -405px;
            background: #eee;
            width: 100vw;
            left: -32px;
            transition: 1s ease-in-out;
            flex-direction: column;
        }
        .header-section .navbar{
            justify-content: space-between;
        }
        #mainmenu .show{
            top: 31px;
        }
}